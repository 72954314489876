#kt_aside_menu_wrapper .menu-link:hover span {
     color: #f08303 !important;
}

.StoreListing{
 background-color: #f08303;
border: none;
outline: none;
color: #fff;
display: inline-block;
padding: 7px 10px;
border-radius: 6px;
}

.CustomTestFooter{
     font-size: 14px;
     color: white;
}
.text-gray-800 .text-hover-primary:hover{
     color: black !important;
}
.MainHH{
     background-color: #f08303 !important;
}

.d-flex .flex-center .flex-column .flex-column-fluid .p-10{
     height: 100vh;
}
.Dropship{
     color: #f08303 !important;
}
#kt_sign_in_submit:hover{
     background-color: #f08303 !important;
}

#kt_header .Latest .home_menu a::after{
 background-color: #f08303 !important;
}
.svg-icon-5 i:before{
     color: #f08303 !important;
}

.menu-item  .ColorAdd::after{
     color: #f08303 !important;
     background-color: #f08303 !important;
}
.BackArrow{
     font-size: 16px !important;
     margin-left: 47px;
}
.BackArrow i{
     font-size: 20px !important;
}
.OrderRad{
     border: 1px solid gainsboro;
     padding: 10px;
     border-radius: 6px;

}
.leftBar{
     background-color: antiquewhite;
}
.card-header .fa-gears{
     color: #f08303 !important;
     font-size: 94px;
}
.card-header .fa-pen-to-square{
     color: #f08303 !important;
     font-size: 94px;
}
.inputShopname{
     font-size: 14px;
     width: 100%;
     text-align: end;
}

/*  */

.left-submenu{
     padding-left:5rem;
     line-height: 25px;
     /* background-color: rgb(255, 145, 0); */
}

.left-submenu input[type="checkbox"] {
     margin-right: 10px;
   }
   
   /* Style for selected currency label */
   .left-submenu p input[type="checkbox"]:checked + span {
     color: rgb(18, 18, 19); 
     font-weight: bold; 
   }
.left-submenu p {
     display: flex;
     align-items: center;
     margin: 5px 0;
     cursor: pointer;
   }
   .form-check-input {
     padding: 7px;
 }
   .form-check-input:checked[type=checkbox] {
     padding: 7px;
 }
 .custom-switch .react-switch-bg {
     background-color: #ccc; /* Change the background color to match your design */
     border: 1px solid #ccc; /* Add a border to mimic the switch appearance */
   }
   
   .custom-switch .react-switch-handle {
     display: none; /* Hide the cross icon */
   }
   

   .switch-container {
     display: inline-block;
     position: relative;
   }
   
   .custom-switch {
     appearance: none;
     -webkit-appearance: none;
     width: 40px;
     height: 20px;
     border-radius: 10px;
     background-color: #ccc; /* Default background color */
     position: relative;
     cursor: pointer;
     outline: none;
   }
   
  
   /* .react-switch-bg {
     background-color: #f08303 !important;
   }
   
   .react-switch-bg .react-switch-disabled {
     background-color: red !important;
   } */

  .Paymentstatus{
    background-color:"gray";
   color:"white"
   }
   .bullet::before {
     content: "\2022"; /* Unicode character for bullet (•) */
     margin-right: 5px;
   }

   /* /// sidebar left in Transfer */
   /* #kt_aside {
     transition: transform 1s ease; 
     transform: translateX(-100%);
        }
 #kt_aside.sidebar-open {
     transform: translateX(0);
 }
  */

  .new_meyt #kt_wrapper .card{
     width: auto;
  }
  @media (max-width: 768px) {
     .inputShopname {
         text-align: start; /* Left align on mobile */
     }
     
 }
body {
  margin: 0;
  font-family: Poppins, Helvetica, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

select.btn {
  color: black !important;
}

select.btn option {
  text-align: left;
}

.input-group>.form-control:focus,
.input-group>.form-floating:focus-within,
.input-group>.form-select:focus {
  z-index: auto !important;
}

.position-relative {
  height: 100%;
}

.card .card-header .card-title {
  align-items: stretch;
}

.dropdown-menu {
  background: #e4e6ef !important;
}

.dropdown-menu li {
  padding: 5px 0px;
  border-bottom: 0.2px solid rgba(0, 0, 0, 0.041);

}

li a {
  color: black;
  padding: 0px 10px;
  width: 100%;
  display: block;
}

.desktopButtonsTD a:hover i {
  color: #fc8a03 !important;
}

.menu-sub .menu-link {
  padding-left: 40px !important;
}

.btn.btn-active-color-danger:hover:not(.btn-active) i {
  color: #f1416c !important;
}

.page-link {
  color: #5e6278 !important;
}

.page-item.disabled .page-link {
  color: #b5b5c3 !important;
  pointer-events: none;
  background-color: transparent;
  border-color: transparent;
}

thead tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important;
}

tbody td {
  border-bottom: none !important;
}

.login_section {
  /* height: 100vh; */
  background-color: #fff;
}

.login_section .shadow-sm {
  box-shadow: 0 0.1rem 1rem 0.25rem rgb(0 0 0 / 5%) !important;
}

.login_section .rounded {
  border-radius: 0.65rem !important;
}

.login_section a {
  color: #fc8a03 !important;
}

.login_section .h1,
.login_section h1 {
  font-size: 1.45rem
}

.login_section .form-control-lg {
  padding: .825rem 1.5rem;
}

.dropdown {
  position: relative;
}

.btn i {
  margin-left: 5px;
}

#kt_header .dropdown-menu.show {
  display: block;
  position: absolute;
  top: 50px;
  right: 0px;
  background-color: white !important;
  border: none;
}

.header {
  padding: 20px;
}

.page {
  min-height: 94vh;
  background-color: #fffbf6;
}

.menu-item span {
  font-size: 13px;
}

.menu-item i {
  font-size: 1.4rem !important;
}

i.fa-warehouse {
  font-size: 1.2rem !important;
}

.asideFirstButton .menu-title {
  font-size: 16px !important;
}

.menu-bullet i {
  font-size: 13px !important;
}

.asideFirstButton .menu-item .menu-link .menu-arrow {
  width: 1.5rem;
  height: 1.5rem;
}

.asideFirstButton .menu-arrow i {
  font-size: 16px !important;
}

#kt_content_container {
  padding: 0px 30px;
}

.asideFirstButton.rounded-2 {
  border-radius: 0.65rem !important;
}

.page-item:not(:first-child) .page-link {
  margin-left: 0px !important;
}

.pagination .page-link {
  border: none !important;
}

.login_section .link-primary:focus,
.login_section .link-primary:hover {
  color: #f08303 !important;
  filter: brightness(0.9);
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: #f5f8fabf !important;
}

#root .fw-bold {
  font-weight: 600 !important;
}

#root .fs-3 {
  font-size: 1.35rem !important;
}

#root .py-4 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

#root .py-4 h3 {
  font-size: 21px;
}

.upload__inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.text-hover-primary:hover {
  color: #f08303 !important;
}

.createTransfer .iconOnInput i {
  background-color: #f08303 !important;
  color: white !important;
  font-size: 18px !important;
}

#edit_product .form-control,
#edit_product .form-select,
.createTransfer .form-control,
.createTransfer .form-select {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #181c32;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  appearance: none;
  border-radius: 0.65rem;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.carousel button {
  font-family: "Line Awesome Free";
  font-weight: 900;
  height: 100px;
  color: black !important;
  opacity: 1;
}

#root .carousel .control-arrow,
#root.carousel.carousel-slider .control-arrow {
  bottom: 0px !important;
  top: auto !important;
  display: flex;
  align-items: flex-end;
  opacity: 1;
}

.control-prev:before {
  content: '\f359' !important;
  border: none !important;
}

.control-next:before {
  content: "\f35a" !important;
  border: none !important;
}


.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, 0.0) !important;
}

#root .pcCaption strong {
  font-size: 16.25px !important;
}

#root .page-link:focus {
  z-index: 3;
  color: #000;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #e9ecef;
}

#root .page-item.active .page-link:focus {
  z-index: 3;
  color: #fff !important;
  background-color: #f08303 !important;
  border-color: transparent;
}

.dropdown-toggle::after {
  margin-left: 0.5em !important;
}

.btn-color-dark i {
  color: #181c32;
}

.customSearchBox select {
  border-radius: 5px 0px 0px 5px !important;
}

.customSearchBox input {
  border-radius: 0px 5px 5px 0px !important;
}

#root .customSearchBtn i {
  font-size: 18px !important;
}

#root .customSearchBtn {
  width: 30px !important;
  height: 30px !important;
  right: 6px;
  border-radius: 5px !important;
  top: calc(50% - 15px) !important;
  padding: 0px !important;
}

.card .card-header {
  min-height: 60px !important;
}

.warehouse td {
  display: flex;
  gap: 150px;
  border: none;
}

.vander_variants tr tr td,
.vander_variants tr tr th,
.vander_variants tr tr {
  vertical-align: top;
  border: 0px !important;
}

.vander_variants td .add_field i {
  margin-top: 10px;
  font-weight: 700;
  color: #f08303;
  cursor: pointer;
}

.add_field i {
  color: #f08303 !important;
}

.vander_variants tr tr {
  display: flex;
  gap: 70px;
}

/* .table.gy-5 td, .table.gy-5 th{
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
} */
.mb-5 {
  margin-bottom: 1rem !important;
}

.card-footer .card-title {
  width: 330px;
}

.card-footer .card-title input {
  padding: 8.5px 10px !important;
  border-radius: 5px !important;
}

select:focus {
  border-color: #e4e6ef !important;
}

#root i {
  color: #181c32;
}

#root .menu-arrow i {
  color: white;
}

#root .tableInput .input-group-text {
  padding: 5px !important;
}

.purchaseOrder .desktopButtonsTD .btn {
  width: 120px;
  background-color: black;
  padding: 8px !important;
}

.card-header i {
  color: white !important;
}

.input-group input {
  width: 15px;
  text-align: center;
  padding: 5px 0px !important;
}

.input-group .btn {
  height: 100%;
}

.input-group .btn {
  height: 100%;
  border: 1px solid #e4e6ef !important;
  color: black;
}

.input-group .btn:hover {
  background-color: transparent;
  color: black
}

.input-group .btn:active {
  background-color: transparent !important;
  color: black
}

.input-group .btn:focus-visible {
  background-color: transparent !important;
  color: black
}

.form-control:focus {
  border: 1px solid #e4e6ef !important;
}

.createTransfer label {
  height: 100%;
  display: flex;
  align-items: center;
}

.customSearchBox .input-group .btn i {
  text-align: left !important;
  color: #fff !important;
}

.customSearchBox .input-group input {
  text-align: left !important;
  padding-left: 10px !important;
}

.card-title {
  padding: 5px 0px;
}

.topbar .btn:hover i {
  color: white !important;
}

.topbar .btn {
  display: flex;
  align-items: center;
}

.table.gs-7 td:last-child,
.table.gs-7 th:last-child {
  padding-right: 1.75rem !important;
}

.btn-icon {
  display: flex;
  align-items: center;
}

.form-floating .form-control {
  min-width: 125px;
}

.card-title select {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.iconOnInput i {
  transform: rotateY(180deg);
}

.createTransfer .table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: transparent !important;
}

.createTransfer .table-striped>tbody>tr:nth-of-type(even)>* td span {
  color: inherit !important;
}

.createTransfer td {
  color: #212529 !important;
}

.readonly {
  background: #f5f8fa !important;
}


.Polaris-DataTable__Cell:nth-child(5) {
  min-width: 125px !important;
}

.Polaris-DataTable__Cell:nth-child(6) {
  min-width: 100px !important;
}

.Polaris-DataTable__Cell:nth-child(7) {
  min-width: 65px !important;
}

.Polaris-DataTable__Cell:nth-child(8) {
  min-width: 100px !important;
}

.Polaris-DataTable__Cell:nth-child(9) {
  min-width: 100px !important;
}

.app-footer .app-container {
  padding: 10px 30px 5px 30px !important;
}


div.dataTables_wrapper {
  width: 800px;
  margin: 0 auto;
}

tr .fix:first-child {
  position: sticky;
  left: 0px;
}

tr .fix:nth-child(2) {
  position: sticky;
  left: 61.75px;
}

.myDrop {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}





.Polaris-Page {
  width: 100%;
  max-width: 100% !important;
}

.Polaris-DataTable__Table {
  overflow-x: scroll;
}

.Polaris-DataTable__Cell:nth-child(2) {
  min-width: 80px;
  font-size: 13.975px;
}

.Polaris-DataTable__Cell:nth-child(3) {
  text-align: left !important;
  min-width: 250px;
  white-space: normal !important;
}

.Polaris-DataTable__Cell img {
  width: 60px !important;
  height: 60px;
  border-radius: 10px;
}

.Polaris-DataTable__FixedFirstColumn {
  box-shadow: 10px 0 9px 0px rgb(0 0 0 / 5%);
}

.Polaris-DataTable__Navigation {
  display: none !important;
}

.Polaris-Page__Content {
  padding: 0px !important;
}

.Polaris-Page__Content th {
  padding-bottom: 1rem !important;
  padding-top: 1.75rem !important;
  vertical-align: middle !important;
}

.Polaris-Page__Content td {
  padding-bottom: 1.75rem !important;
  padding-top: 1.75rem !important;
  vertical-align: middle !important;
}

.Polaris-Page__Content thead th {

  font-weight: 600 !important;
}

.Polaris-DataTable__Cell {
  text-align: center !important;
}

.Polaris-DataTable__Cell--header {
  min-width: 125px;
}

.Polaris-DataTable__Cell--header:first-child {
  min-width: min-content;
}

.table-striped>tbody>tr:nth-of-type(odd)>* td:first-child {
  background-color: #f5f8fa !important;
  box-shadow: 10px 0 9px 0px rgb(0 0 0 / 5%);
}

.table-striped>tbody>tr:nth-of-type(even)>* td:first-child {
  background-color: #fff !important;
  box-shadow: 10px 0 9px 0px rgb(0 0 0 / 5%);

}

.vander_variants th:first-child {
  box-shadow: 10px 0 9px 0px rgb(0 0 0 / 5%);
  background-color: #fff;
}

.vander_variants th:first-child,
.vander_variants td:first-child {
  position: sticky;
  left: 0;
}


.sales_rev select,
.sales_unit select {
  padding: 4px;
  border: 1px solid rgba(170, 170, 170, 0.315);
  background-color: transparent;
  font-size: 90%;
}

.sales_rev select:focus,
.sales_unit select:focus {
  outline: 0px;
}

.Polaris-Page__Content thead th {
  vertical-align: top !important;
}


.card-footer .card-title {
  margin-bottom: 4rem;
}

.footer_last_row {
  display: flex;
  justify-content: space-between;
}

td .boxIcon {
  width: 120px;
  font-size: 90%;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.fa-lightbulb-on:before {
  content: "\f672"
}

#root .form-select {
  background-image: url("/src/assets/crImages/down.png") !important;
  background-size: 13px 11px !important;
}

#root .menu-item .menu-link .menu-arrow:after {
  background-image: url("/src/assets/crImages/arrow_down.png") !important;
  transform: rotateZ(0deg);
  transition: transform .3s ease;
  background-size: 13px 13px;
  background-position: 0px;
}

span.select2-selection.select2-selection--multiple {
  background-repeat: no-repeat !important;
  background-position: 99% !important;
  border: 1px solid #c9cccf;
  padding: 0px 5px 3px;
  line-height: 33px;
}

.asideFirstButton .menu-arrow:after {
  background-image: none !important;
  background-size: 0px !important;
}

#root .menu-item .menu-link .menu-arrow {
  width: 1.8rem;
}

.Polaris-Page {
  padding: 0px !important;
}

.btn.btn-color-primary {
  color: #f08303;
}

.btn.btn-color-primary .svg-icon svg [fill]:not(.permanent):not(g) {
  fill: #f08303 !important;
}

.form-select option {
  width: 200px;
  max-width: 200px;

}

.feedForm {
  padding-top: 25px;
  padding-left: 25px;

}

.feedForm1 {
  /* padding-top: 25px; */
  padding-left: 25px;

}

.rule-head {
  font-size: 16px;
  margin: 20px;
}

.subheading {
  font-size: 100;
}

.languageCheck {
  margin: 10px 0 0 31px !important;
}

.languageLabel {
  margin: 5px 0 0 6px !important;
  font-family: 'Poppins, Helvetica, sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  padding: 5px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;

}

.multipleSelect{
  margin-left: -30px;
}

.disconnect {
  background-color: #D92E3A;
  border: none;
  outline: none;
  color: #fff;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 6px;
}

.form-check-input:checked {
  background-color: #fc8a03 !important;
  border-color: #fc8a03 !important;
}

.form-check-input:focus {
  border-color: #00000040 !important;
  outline: 0;
  box-shadow: none !important;
}

.info-text {
  display: inline-block;
  margin: 0 0 30px 15px;
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e") !important;
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
}

.form-switch .form-check-input {
  width: 3em !important;
  margin-left: -2.5em !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position .15s ease-in-out;
}

.diff {
  margin-left: 10px;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #ced4da !important;
  outline: 0;
  box-shadow: none !important;
}

.form-check:not(.form-switch) .form-check-input[type=checkbox] {
  background-size: 100% 100%;
}


.rule-section {
  background: #fff;
  padding: 10px;
  border-radius: 15px;
}

.rev-section {
  background: #fff;
  padding: 10px;
  border-radius: 15px;
  padding: 25px;
}

.rule-table {
  width: 80%;
  margin: 20px;
}

.rule-table th {
  background-color: #f1f1f1 !important;
  padding: 14px;
}

.rule-table thead th {
  font-size: 14px;
}

.rule-table tbody td {
  padding: 7px;
}

.platform-title {
  font-size: 1.90rem !important;
  padding: 25px 25px 0 25px;
}

.btn-connection {
  background-color: #C8EDB3;
  border: none;
  color: black;
  padding: 18px 25px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
}

.btn-skip {
  background-color: #9dc367 !important;
  margin-left: 30px;
  font-size: 16px;
  padding: 18px 40px;
}

.rule-footer .btn-save {
  margin-left: 30px;
  font-size: 16px;
}

.rule-footer .sche-save {
  text-align: center;
}

#kt_content .card.border {
  min-height: 200px;
}

.border {
  background: #f8f8f8 !important;
  border-radius: 20px !important;
}

.sche-body {
  margin-left: 35px;
}

.rule-section .sche-sec2 {
  padding: 15px;
  margin: 20px 15px 20px 15px;
  background-color: #f8f8f8 !important;
  border-radius: 10px;
  flex-wrap: nowrap;
}

.stack {
  display: flex;
  justify-content: space-around;
}

.rev-sec1 {
  padding: 20px;
  font-size: 15px;
  background-color: #f8f8f8 !important;
  border-radius: 10px;
  flex-wrap: nowrap;
  margin-bottom: 20px;
}

.rev-sec1 h3 {
  display: inline-block;
  margin-left: 5px;
  font-size: 14px;
}

.rev-sec1 small {
  display: inline-block;
  margin-left: 20px;
}

.sche-body label {
  margin-right: 15px;
  font-size: 14px;
}

.b-div {
  padding: 0 30px 30px 30px;
}

.b-div-rev {
  padding: 0 30px 0px 30px;
}

.auto-box {
  background-color: #fff !important;
  padding: 15px;
  align-items: center;
  border-radius: 10px;
}

.info-icon {
  width: 20px;
  display: inline-block;
  color: #9FD87F
}

.info-icon-rev1 {
  min-width: 15px;
  display: inline-block;
  color: #9FD87F
}

.info-icon-rev2 {
  width: 25px;
  display: inline-block;
  color: #9FD87F
}

.btn-rev {
  text-align: center;
  margin: 30px 0 30px 0;
}

.btn-rev-save {
  background-color: #C8EDB3;
  border: none;
  color: black;
  padding: 18px 25px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
}


#kt_aside {
  transition: all ease-in-out 1s;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #fff1f2 !important;
}

#kt_aside {
  position: relative !important;
  top: unset;
  bottom: 0;
  margin-top: 104px;
  left: unset;
}
/*  Responsiveness Of the Design */

@media (max-width: 1024px) {
  .card-header .card-title {
    flex-direction: column;
  }

  #root .customSearchBtn {
    width: 28px !important;
    height: 28px !important;
    right: 2px;
    top: calc(50% - 13px) !important;
  }

  .page .fs-1 {
    font-size: calc(1.375rem + 0.5vw) !important;
  }

  .form-select {
    width: 170px !important;
    max-width: 170px !important;
  }
}

@media (max-width:912px) {
  .page #kt_content {
    margin-top: 60px;
  }

  #kt_aside {
    display: none;
  }

  #kt_aside .card .card-header .card-toolbar {
    align-content: flex-start;
  }

  .topDropdownList {
    flex-wrap: wrap;
  }
}

@media (max-width: 767px) {
  .customSearchBox input.form-control.border {
    min-width: auto !important;
  }

  .topDropdownList .form-select {
    max-width: 100% !important;
    width: 100% !important;
  }

  .card-footer .tablePaginationMain {
    flex-direction: column;
  }

  .card .card-header:nth-child(2).py-4 {
    order: -1;
  }

  .card .card-header:nth-child(2) .listingCounter {
    padding-bottom: 1rem 0px !important;
  }

  .card .card-header:nth-child(2) .iconsexport {
    padding: 1rem 0px !important;
  }

  .dataTables_info {
    display: inline;
  }

  .card .card-header:nth-child(2) .dots {
    margin-left: 10px;
  }

  .card .card-header:nth-child(2) .dots span {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #181c32;
    display: inline-block;
    margin: 1px;
  }

  .card .card-header:nth-child(2) .ms-15 {
    display: flex;
    margin-left: 10px !important;
  }

  #filter_field_1 {
    display: none;
  }
}

.rev-sec1 h3 {
  width: calc(100% - 15px);
}

.textWithIcon {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

.config_set_page .wrapper {
  padding-top: 0;
}

.config_set_page #kt_aside {
  margin-top: 20px;
}

.head_met h1 {
  font-size: 20px !important;
  font-weight: 600 !important;
  margin-bottom: 10px;
}

.head_met p {
  font-size: 16px;
}

.col.stack {
  max-width: 100%;
  margin: 0 auto;
}

.config_set_page .rule-section .sche-sec2 {
  background-color: transparent !important;
}

.config_set_page .rule-section .stack .form-check {
  background-color: #EEEEEE;
}

.js-example-basic-curr {
  width: 70.20em !important;
}

@media (max-width: 1190px) {
  .wrapper {
      padding-left: 80px !important;
  }
}
@media (max-width: 991px) {
  .wrapper {
      padding-left: 100px !important;
      padding-top: 77px;
  }
  .page > .container > .row .col-10 {
      width: 100% !important;
  }
  .wrapper {
      padding-left: 0px !important;
      padding-top: 77px;
  }
  .page > .container > .row > .col-2 {
      z-index: 999;
  }
  #kt_content_container {
      padding: 0;
  }
  #kt_content_container .card-header .col-md-2 {
      flex: 0 0 auto;
      width: auto !important;
  }
  #kt_content .card .card-header {
      padding: 10px 20px !important;
  }
  
  div#kt_aside {
      /* transform: translateX(-100%) !important; */
      position: absolute !important;
      left: 0 !important;
      margin-top: 73px !important;
      top: 0 !important;
  }
  div#kt_aside.drawer-on {
      position: absolute !important;
      left: 0 !important;
      transform: translateX(0%) !important;
      z-index: 1 !important;
      width: 250px !important;
  }
  .page #kt_content {
      margin-top: 6px;
      padding-top: 0;
  }
  #kt_content .rev-sec1 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      padding: 20px;
  }
  #kt_content .rev-sec1 > div {
      width: 100% !important;
  }

}

@media (max-width: 650px) {
  #kt_content .rev-sec1 {
      grid-template-columns: 1fr;
  }
  .config_set_page .rule-section .stack {
      padding: 0;
      overflow: auto;
      display: flex;
      justify-content: flex-start;
  }
  .config_set_page .rule-section .stack .form-check {
      min-width: 150px;
  }
  .info-text {
      width: calc(100% - 35px);
  }
}
.time-input{
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.time-input::-webkit-datetime-edit-ampm-field {
  display: none;
  }